import React, { useState, useMemo } from 'react';
import { FaCarSide, FaMotorcycle } from 'react-icons/fa';
import { UserIcon, OfficeBuildingIcon } from '@heroicons/react/outline';

import EditPartModal from '../../../modals/EditPartModal/EditPartModal';
import EditVehicleModal from '../../../modals/EditVehicleModal/EditVehicleModal';
import { Company, User, Car, RegistrationTramit } from '../../../types/types';
import Card from '../../components/Card';
import ReplaceCounterpartModal, {
  PossibleCounterpart,
} from '../../../components/ReplaceCounterpartModal';
import ReplaceVehicleModal from '../../../components/ReplaceVehicleModal';
import { getCounterpartEntityType } from '../../../helpers/getCounterpartEntityType';
import {
  generateCompanySection,
  generateUserSection,
  generateCompanyFileSection,
  generateUserFileSection,
  generateVehicleFiles,
  generateVehicleSection,
} from '../../../helpers/generateCardSections';

function GeneralInfo({
  tramit,
  forceUpdate,
}: {
  tramit: RegistrationTramit;
  forceUpdate: () => void;
}) {
  const [replaceModalData, setReplaceModalData] = useState<{
    counterPart: PossibleCounterpart;
    open: boolean;
  }>({
    counterPart: 'buyer',
    open: false,
  });
  const [replaceVehicleModalOpen, setReplaceVehicleModalOpen] = useState(false);

  const vehicle = tramit?.car;
  const buyer = tramit?.buyer;

  const [editPartModalInfo, setEditPartModalInfo] = useState<{
    isOpen: boolean;
    type: 'Comprador' | 'Vendedor';
    preloadedValue: User | Company;
  }>({
    isOpen: false,
    type: 'Comprador',
    preloadedValue: null,
  });

  const [editVehicleModalInfo, setEditVehicleModalInfo] = useState<{
    isOpen: boolean;
    preloadedValue: Car;
  }>({
    isOpen: false,
    preloadedValue: null,
  });

  const setEditPartModalOpen = (value: boolean) =>
    setEditPartModalInfo({
      ...editPartModalInfo,
      isOpen: value,
    });

  const setEditVehicleModalOpen = (value: boolean) =>
    setEditVehicleModalInfo({
      ...editVehicleModalInfo,
      isOpen: value,
    });

  const setReplaceModalOpen = (value: boolean): void => {
    setReplaceModalData({
      ...replaceModalData,
      open: value,
    });
  };

  const replaceCounterpartType = useMemo(
    getCounterpartEntityType({
      counterPartRole: replaceModalData.counterPart,
      buyer,
    }),
    [replaceModalData.counterPart, buyer],
  );

  if (!tramit) return null;

  return (
    <>
      <ReplaceCounterpartModal
        counterpart={replaceModalData.counterPart}
        open={replaceModalData.open}
        setOpen={setReplaceModalOpen}
        forceUpdate={forceUpdate}
        sourceEntityId={buyer?.id}
        type={replaceCounterpartType}
      />
      <ReplaceVehicleModal
        open={replaceVehicleModalOpen}
        setOpen={setReplaceVehicleModalOpen}
        forceUpdate={forceUpdate}
        sourceCarId={vehicle?.id}
      />
      <EditPartModal
        isOpen={editPartModalInfo.isOpen}
        setIsOpen={setEditPartModalOpen}
        onSave={() => {
          forceUpdate();
        }}
        preloadedValue={editPartModalInfo.preloadedValue}
        type={editPartModalInfo.type}
      />
      <EditVehicleModal
        procedureType="REGISTRATION"
        code={tramit.registration.registrationCode}
        isOpen={editVehicleModalInfo.isOpen}
        setIsOpen={setEditVehicleModalOpen}
        onSave={() => {
          setEditVehicleModalOpen(false);
          forceUpdate();
        }}
        preloadedValue={{
          price: null,
          ...editVehicleModalInfo.preloadedValue,
        }}
      />
      {buyer && (
        <Card
          title="Comprador"
          subtitle={
            (buyer as Company).representativeId ? 'Empresa' : 'Particular'
          }
          subtitleIcon={
            (buyer as Company).representativeId ? (
              <OfficeBuildingIcon className="h-4" />
            ) : (
              <UserIcon className="h-4" />
            )
          }
          sections={
            (buyer as Company).representativeId
              ? [
                  generateCompanySection(buyer as Company),
                  (buyer as Company).representative &&
                    generateUserSection(
                      (buyer as Company).representative,
                      'Administrador de la empresa',
                    ),
                ]
              : [generateUserSection(buyer as User, 'Datos personales')]
          }
          files={
            (buyer as Company).representativeId
              ? generateCompanyFileSection(buyer as Company)
              : generateUserFileSection(buyer as User)
          }
          onEdit={() => {
            setEditPartModalInfo({
              isOpen: true,
              type: 'Comprador',
              preloadedValue: buyer,
            });
          }}
          onReplace={() => {
            setReplaceModalData({
              open: true,
              counterPart: 'buyer',
            });
          }}
        />
      )}
      {vehicle && (
        <Card
          title="Vehículo"
          subtitle={vehicle?.isMoto ? 'Moto' : 'Coche'}
          subtitleIcon={
            vehicle?.isMoto ? (
              <FaMotorcycle className="h-4" />
            ) : (
              <FaCarSide className="h-4" />
            )
          }
          sections={generateVehicleSection({
            price: null,
            ...vehicle,
          })}
          files={generateVehicleFiles(vehicle)}
          onReplace={() => {
            setReplaceVehicleModalOpen(true);
          }}
          onEdit={() =>
            setEditVehicleModalInfo({ isOpen: true, preloadedValue: vehicle })
          }
        />
      )}
    </>
  );
}

export default GeneralInfo;
