import { useNavigate } from 'react-router-dom';
import { useReducer, useRef, useState } from 'react';

import { useServices } from '../../../../services';
import Spinner from '../../../../components/Spinner';
import useClickOutside from '../../../../hooks/useClickOutside';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_NIF':
      return { ...state, nif: action.payload };
    case 'SET_EMAIL':
      return { ...state, billingEmail: action.payload };
    case 'SET_PLATFORM':
      return { ...state, platform: action.payload };
    default:
      return state;
  }
};

export default function CreateNewAccount({ setIsModalOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formState, dispatch] = useReducer(reducer, {
    name: '',
    nif: '',
    billingEmail: '',
    platform: '',
  });

  const navigate = useNavigate();
  const { createNewAccount } = useServices();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setIsModalOpen(false));

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setHasError(false);
      setIsLoading(true);

      const account = await createNewAccount({
        nif: formState.nif,
        name: formState.name,
        billingEmail: formState.billingEmail,
        platform: formState.platform,
      });

      setIsModalOpen(false);
      navigate(`/account/${account.id}`);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full fixed left-0 top-0 bg-black/75 z-30 flex items-center justify-center">
      <div
        ref={modalRef}
        className="p-10 flex flex-col items-start bg-white rounded-md shadow-md"
      >
        <h2 className="text-xl font-medium mb-5">Nueva cuenta</h2>
        <form onSubmit={handleSubmit}>
          <div className="w-80">
            <div className="flex">
              <label className="sr-only">Nombre de la cuenta</label>
              <input
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                type="text"
                placeholder="Nombre de la cuenta"
                value={formState.name}
                onChange={(e) =>
                  dispatch({ type: 'SET_NAME', payload: e.target.value })
                }
              />
            </div>
            <div className="flex">
              <label className="sr-only">NIF de la empresa</label>
              <input
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                type="text"
                placeholder="NIF de la empresa"
                value={formState.nif}
                onChange={(e) =>
                  dispatch({ type: 'SET_NIF', payload: e.target.value })
                }
              />
            </div>
            <div className="flex">
              <label className="sr-only">Email de facturación</label>
              <input
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                type="email"
                placeholder="Email de facturación"
                value={formState.billingEmail}
                onChange={(e) =>
                  dispatch({ type: 'SET_EMAIL', payload: e.target.value })
                }
              />
            </div>
            <div className="flex">
              <label className="sr-only" htmlFor="platform">
                Plataforma
              </label>
              <select
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                name="platform"
                id="platform"
                defaultValue=""
                onChange={(e) =>
                  dispatch({ type: 'SET_PLATFORM', payload: e.target.value })
                }
              >
                <option value="" disabled>
                  -- Seleccionar Plataforma --
                </option>
                <option value="SWIPOOPRO">SWIPOO PRO</option>
                <option value="ANCOVE">ANCOVE</option>
              </select>
            </div>
          </div>
          <div className="flex gap-5 items-center mt-5 ">
            <button
              type="submit"
              className="px-4 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Crear
            </button>
            {isLoading && <Spinner color="blue" size={6} marginTop={0} />}
          </div>
          {hasError && (
            <p className="text-sm text-red-500 mt-2">
              Ha ocurrido un error al crear la empresa.
            </p>
          )}
        </form>
      </div>
    </div>
  );
}
