import { MdMail, MdPhone } from 'react-icons/md';
import React from 'react';
import PersonArrowLeftIcon from '../icons/PersonArrowLeftIcon';
import PersonArrowRightIcon from '../icons/PersonArrowRightIcon';
import { User } from '../../../../../types/types';

// eslint-disable-next-line no-shadow
export enum AvailableRecipientRoles {
  BUYER = 'Comprador',
  SELLER = 'Vendedor',
}

export const hasCommunicationMethod = (recipient: User) => {
  if (!recipient) {
    return false;
  }

  return recipient.email?.length > 0 || recipient.phone?.length > 0;
};

export default function RecipientItem({
  recipientRole,
  recipient,
}: {
  readonly recipientRole: AvailableRecipientRoles;
  readonly recipient: User;
}) {
  if (!recipient || !hasCommunicationMethod(recipient)) {
    return (
      <section className="flex gap-6 items-center text-zinc-600">
        <figure className="flex flex-col justify-center items-center gap-1.5 bg-zinc-100 rounded-2xl size-28 p-4 font-bold text-sm">
          {recipientRole === AvailableRecipientRoles.BUYER && (
            <>
              <PersonArrowLeftIcon className="size-11" />
              <figcaption>Comprador</figcaption>
            </>
          )}
          {recipientRole === AvailableRecipientRoles.SELLER && (
            <>
              <PersonArrowRightIcon className="size-11" />
              <figcaption>Vendedor</figcaption>
            </>
          )}
        </figure>
        <article className="flex flex-col gap-1.5">
          <span className="font-semibold text-lg">
            No hay datos disponibles del {recipientRole.toLowerCase()}
          </span>
          <span className="text-sm w-80">
            Es necesario completar esta información para enviar la solicitud de
            firma digital al {recipientRole.toLowerCase()}.
          </span>
        </article>
      </section>
    );
  }

  return (
    <article className="flex justify-between items-center">
      <section className="flex gap-6 items-center">
        <figure className="flex flex-col justify-center items-center gap-1.5 bg-green-100 text-green-800 rounded-2xl size-28 p-4 font-bold text-sm">
          {recipientRole === AvailableRecipientRoles.BUYER && (
            <>
              <PersonArrowLeftIcon className="size-11" />
              <figcaption>Comprador</figcaption>
            </>
          )}
          {recipientRole === AvailableRecipientRoles.SELLER && (
            <>
              <PersonArrowRightIcon className="size-11" />
              <figcaption>Vendedor</figcaption>
            </>
          )}
        </figure>
        <article className="flex flex-col gap-1.5">
          <span className="font-bold">{`${recipient.name} ${recipient.surname}`}</span>
          <article className="text-sm flex flex-col gap-0.5">
            <span className="flex items-center gap-2 text-zinc-600">
              <MdMail className="size-4" /> {recipient.email || 'No disponible'}
            </span>
            <span className="flex items-center gap-2 text-zinc-600">
              <MdPhone className="size-4" />{' '}
              {recipient.phone || 'No disponible'}
            </span>
          </article>
        </article>
      </section>
      <select className="border-0 py-2.5 font-semibold text-blue-800 rounded-lg hover:bg-blue-50">
        {recipient.email && <option>Correo electrónico</option>}
        {recipient.phone && <option>Teléfono móvil</option>}
      </select>
    </article>
  );
}
