import React, { useEffect, useState } from 'react';

import classNames from '../../../../../helpers/classNames';
import Spinner from '../../../../../components/Spinner';

type Props = Readonly<{
  selectedTemplateId: string;
}>;

export default function TemplatePreview({ selectedTemplateId }: Props) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
  }, [selectedTemplateId]);

  return (
    <aside className="relative w-[600px] h-[670px] rounded-xl overflow-hidden">
      {loading && (
        <article className="absolute flex items-center justify-center w-full h-full">
          <Spinner color="text-blue-700" size={16} marginTop={0} />
        </article>
      )}
      <iframe
        title="preview"
        className={classNames('block aspect-auto', loading && 'invisible')}
        width="100%"
        height="100%"
        src={`https://docs.google.com/document/d/${selectedTemplateId}/preview`}
        onLoad={() => setLoading(false)}
      />
    </aside>
  );
}
