export const fuelDictionary: { [key: string]: string } = {
  G: 'Gasolina',
  D: 'Diesel',
  Elc: 'Eléctrico',
  DyE: 'Híbrido Diesel y Eléctrico',
  GyE: 'Híbrido Gasolina y Eléctrico',
  S: 'Gasolina GLP',
  M: 'Etanol + Gasolina/Bio',
  H: 'Hidrógeno',
};
