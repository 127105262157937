import { useEffect, useState, useReducer, useContext } from 'react';
import { PlusIcon, TrashIcon } from '@heroicons/react/solid';
import { RiLockPasswordFill } from 'react-icons/ri';

import { parseDateHourYear } from '../../../../helpers';
import Spinner from '../../../../components/Spinner';
import { useServices } from '../../../../services';
import SwipooMembersModal from './components/SwipooMembersModal';
import NewMemberModal from './components/NewMemberModal';
import { accountDetailsContext } from '../../../../context/account-details.context';
import ResetPasswordModal from './components/ResetPasswordModal';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSwipooMembers, setOpenSwipooMembers] = useState(false);
  const [newMemberModal, setNewMemberModal] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState(null);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getAccountMembers, getResetPasswordLink, removeAccountMember } =
    useServices();
  const { account } = useContext(accountDetailsContext);

  useEffect(() => {
    (async () => {
      const response = await getAccountMembers(account.id);
      setUsers(response);
      setIsLoading(false);
    })();
  }, [account.id, updater]);

  const handleResetPassword = async (memberId) => {
    const response = await getResetPasswordLink(memberId);
    setResetPasswordLink(response.ticket);
  };

  const handleRemoveUser = async (memberId) => {
    await removeAccountMember(account.id, memberId);
    forceUpdate();
  };

  if (isLoading)
    return (
      <div className="flex justify-center">
        <Spinner color="text-blue-600" size={10} />
      </div>
    );

  return (
    <>
      {openSwipooMembers && (
        <SwipooMembersModal
          refresh={forceUpdate}
          accountId={account.id}
          setOpenSwipooMembers={setOpenSwipooMembers}
        />
      )}
      {newMemberModal && (
        <NewMemberModal
          refresh={forceUpdate}
          accountId={account.id}
          setNewMemberModal={setNewMemberModal}
        />
      )}
      {resetPasswordLink && (
        <ResetPasswordModal
          resetPasswordLink={resetPasswordLink}
          setResetPasswordLink={setResetPasswordLink}
        />
      )}
      <div className="flex items-center">
        <div className="flex-auto" />
        <div className="flex">
          <button
            onClick={() => setOpenSwipooMembers(true)}
            type="button"
            className="mr-4 rounded-md bg-blue-50 px-2.5 py-1.5 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
          >
            Añadir usuario de Swipoo
          </button>
          <button
            onClick={() => setNewMemberModal(true)}
            type="button"
            className="rounded-full bg-blue-600 p-1.5  text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="flow-root mt-4">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Correo verificado
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Ultima sesión
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Nº de inicios de sesión
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users.map((u) => {
                  const userHasPassword = u.id.split('|')[0] === 'auth0';
                  return (
                    <tr key={u.id}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className="h-11 w-11 rounded-full"
                              src={u.picture}
                              alt="foto perfil"
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {u.name}
                            </div>
                            <div className="mt-1 text-gray-500">{u.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {u.emailVerified ? (
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Verificado
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                            No verificado
                          </span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {u.lastLogin ? parseDateHourYear(u.lastLogin) : 'Nunca'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {u.loginsCount ?? 0}
                      </td>
                      <td className="flex whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {userHasPassword && (
                          <RiLockPasswordFill
                            className="shrink-0 mr-3 h-5 w-5 cursor-pointer"
                            onClick={() => handleResetPassword(u.id)}
                          />
                        )}
                        <TrashIcon
                          className="h-5 w-5 cursor-pointer text-red-500"
                          onClick={() => handleRemoveUser(u.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
