import React, { useContext } from 'react';
import {
  BateTramit,
  RegistrationTramit,
  Transfer,
  TemplateType,
} from '../../../../types/types';
import Spinner from '../../../../components/Spinner';

import useTemplates from './hooks/useTemplates';
import TemplatePicker from './components/TemplatePicker';
import TemplatePreview from './components/TemplatePreview';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';

export type Template = {
  id: string;
  name: string;
  modifiedTime: string;
  thumbnailLink: string;
  type: TemplateType;
  default: boolean;
};

type ChooseTemplateStepProps = Readonly<{
  procedure: Transfer | BateTramit | RegistrationTramit;
  templateType: TemplateType;
}>;

export default function ChooseTemplateStep({
  procedure,
  templateType,
}: ChooseTemplateStepProps) {
  const { setGeneratedDocumentId } = useContext(digitallySignContext);
  const {
    templates,
    selectedTemplateId,
    setSelectedTemplateId,
    injectTemplate,
    injectedDocumentId,
  } = useTemplates(templateType);

  return (
    <section className="flex items-center gap-9">
      {!injectedDocumentId.isLoading && (
        <>
          <TemplatePicker
            templates={templates}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            setSelectedDocId={setGeneratedDocumentId}
            procedure={procedure}
            injectTemplate={injectTemplate}
            injectedDocumentId={injectedDocumentId}
          />
          {selectedTemplateId && (
            <TemplatePreview selectedTemplateId={selectedTemplateId} />
          )}
        </>
      )}
      {injectedDocumentId.isLoading && (
        <article className="flex flex-col items-center justify-center p-8 gap-4">
          <Spinner color="text-blue-700" size={16} marginTop={0} />
          <span>Generando documento</span>
        </article>
      )}
    </section>
  );
}
