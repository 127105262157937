import React, { useContext } from 'react';
import { MdArrowForward, MdClose } from 'react-icons/md';

import { DigitallySignSteps } from './DigitallySignStepsManager';
import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';
import FileUploadIcon from '../../../Modules/Documents/DocumentsTable/icons/FileUploadIcon';
import DocumentSignIcon from '../../../Modules/Documents/DocumentsTable/icons/DocumentSignIcon';
import classNames from '../../../helpers/classNames';

type ChooseDocumentSourceStepProps = {
  hide: () => void;
};

export enum ChooseDocumentSourceStepOptions {
  UPLOAD_FROM_COMPUTER = 'UPLOAD_FROM_COMPUTER',
  USE_TEMPLATE = 'USE_TEMPLATE',
}

export default function ChooseDocumentSourceStep({
  hide,
}: Readonly<ChooseDocumentSourceStepProps>) {
  const [selectedOption, setSelectedOption] =
    React.useState<ChooseDocumentSourceStepOptions>();

  const { setCurrentDigitallySignStep, setChosenDocumentSource } =
    useContext(digitallySignContext);

  const handleNextStep = () => {
    setChosenDocumentSource(selectedOption);
    if (selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE) {
      setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_TEMPLATE);
      return;
    }
    setCurrentDigitallySignStep(DigitallySignSteps.UPLOAD_DOCUMENT);
  };

  const handleHideModal = () => {
    setSelectedOption(null);
    hide();
  };

  return (
    <section className="flex flex-col gap-14">
      <header>
        <h1 className="text-3xl text-zinc-700 font-bold">
          Firmar nuevo documento
        </h1>
      </header>
      <section role="radiogroup" className="flex gap-4">
        <button
          type="button"
          role="radio"
          aria-checked={
            selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE
          }
          className={classNames(
            'flex flex-col items-start gap-6 rounded-2xl p-8 border-2',
            selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE
              ? 'bg-blue-50 border-blue-800 text-blue-800'
              : 'border-zinc-200 hover:bg-zinc-50',
          )}
          onClick={() =>
            setSelectedOption(ChooseDocumentSourceStepOptions.USE_TEMPLATE)
          }
        >
          <DocumentSignIcon
            className={classNames(
              'size-14',
              selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE
                ? 'text-blue-800'
                : 'text-zinc-700',
            )}
          />
          <section className="flex flex-col items-start gap-2">
            <h2
              className={classNames(
                'text-xl font-semibold',
                selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE
                  ? 'text-blue-800'
                  : 'text-zinc-700',
              )}
            >
              Usar una plantilla
            </h2>
            <span
              className={classNames(
                'text-left w-80',
                selectedOption === ChooseDocumentSourceStepOptions.USE_TEMPLATE
                  ? 'text-blue-800'
                  : 'text-zinc-600',
              )}
            >
              Genera un nuevo documento para que el cliente firme digitalmente.
            </span>
          </section>
        </button>
        <button
          type="button"
          role="radio"
          aria-checked={
            selectedOption ===
            ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER
          }
          className={classNames(
            'flex flex-col gap-6 rounded-2xl p-8 border-2',
            selectedOption ===
              ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER
              ? 'bg-blue-50 border-blue-800 text-blue-800'
              : 'border-zinc-200 hover:bg-zinc-50',
          )}
          onClick={() =>
            setSelectedOption(
              ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER,
            )
          }
        >
          <FileUploadIcon
            className={classNames(
              'size-14',
              selectedOption ===
                ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER
                ? 'text-blue-800'
                : 'text-zinc-700',
            )}
          />
          <section className="flex flex-col items-start gap-2">
            <h2
              className={classNames(
                'text-xl font-semibold',
                selectedOption ===
                  ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER
                  ? 'text-blue-800'
                  : 'text-zinc-700',
              )}
            >
              Subir desde mi ordenador
            </h2>
            <span
              className={classNames(
                'text-left w-80',
                selectedOption ===
                  ChooseDocumentSourceStepOptions.UPLOAD_FROM_COMPUTER
                  ? 'text-blue-800'
                  : 'text-zinc-600',
              )}
            >
              Preferible cuando el documento ya está completado y solo requiere
              firma.
            </span>
          </section>
        </button>
      </section>
      <footer className="w-full flex justify-end gap-8">
        <button
          type="button"
          onClick={handleHideModal}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdClose className="size-5" />
          Cancelar
        </button>
        <button
          type="button"
          disabled={!selectedOption}
          onClick={handleNextStep}
          className={classNames(
            'flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed',
            selectedOption && 'hover:bg-blue-900 active:bg-blue-950',
          )}
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </section>
  );
}
