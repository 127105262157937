import axios from 'axios';

export const skodaOCR = async (
  fileType: 'doi' | 'circulation_permit' | 'tif',
  payload: {
    uri: string;
  },
) =>
  axios
    .post(`https://skoda.swipoo.com/ocr/${fileType}`, payload, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SKODA_TOKEN}`,
      },
    })
    .then((res) => res.data);
