import React, { Dispatch, SetStateAction } from 'react';
import RecipientsTabGroupItem from './RecipientsTabGroupItem';

// eslint-disable-next-line no-shadow
export enum RecipientRolesTabGroupOptions {
  BUYER = 'Comprador',
  SELLER = 'Vendedor',
  BOTH = 'Ambos',
}

interface RecipientsTabGroupProps {
  selectedRecipient: RecipientRolesTabGroupOptions;
  setSelectedRecipient: Dispatch<SetStateAction<RecipientRolesTabGroupOptions>>;
}

export default function RecipientsTabGroup({
  selectedRecipient,
  setSelectedRecipient,
}: Readonly<RecipientsTabGroupProps>) {
  return (
    <section className="flex items-center gap-2 p-2 bg-zinc-100 text-zinc-600 font-semibold text-lg rounded-2xl w-fit">
      <RecipientsTabGroupItem
        selectedRecipient={selectedRecipient}
        setSelectedRecipient={setSelectedRecipient}
        recipient={RecipientRolesTabGroupOptions.BUYER}
      />
      <RecipientsTabGroupItem
        selectedRecipient={selectedRecipient}
        setSelectedRecipient={setSelectedRecipient}
        recipient={RecipientRolesTabGroupOptions.SELLER}
      />
      <RecipientsTabGroupItem
        selectedRecipient={selectedRecipient}
        setSelectedRecipient={setSelectedRecipient}
        recipient={RecipientRolesTabGroupOptions.BOTH}
      />
    </section>
  );
}
