import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TrafficReportsTableProps } from '../..';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import { parseDayMonthYearHourMinute } from '../../../helpers/parseDate';
import { handleViewTrafficReport } from '../../../helpers/handleViewTrafficReport';

export default function TrafficReportsTable({
  trafficReports,
}: TrafficReportsTableProps) {
  const [dateSort, setDateSort] = useState('DESC');

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  const { getAccessTokenSilently } = useAuth0();

  const handleClick = async (trafficReportId: string) => {
    const token = await getAccessTokenSilently();

    await handleViewTrafficReport(trafficReportId, token);
  };

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params]);

  return (
    <div className="overflow-x-auto min-h-fit shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="table-fixed min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:px-6 uppercase"
            >
              Matrícula
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
            >
              Bastidor
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
              onClick={
                dateSort === 'DESC'
                  ? () => setSingleParam('dateSort', 'ASC')
                  : () => setSingleParam('dateSort', 'DESC')
              }
            >
              Fecha de creación{' '}
              {dateSort === 'DESC' ? (
                <ChevronDownIcon className="h-3" />
              ) : (
                <ChevronUpIcon className="h-3" />
              )}
            </th>
            <th
              scope="col"
              className="sticky right-0 bg-gray-50 py-3.5 pl-3 pr-4 shadow-md sm:pr-6"
            >
              <span className="sr-only">Ver</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {trafficReports.map((trafficReport, index) => (
            <tr
              key={trafficReport.id}
              className={`${
                index % 2 === 0 ? undefined : 'bg-gray-50'
              } hover:bg-gray-100`}
            >
              <td className="min-w-40 px-3 sm:px-6 align-middle whitespace-nowrap text-sm text-gray-900 flex items-center gap-3 h-14 font-medium">
                {trafficReport.plate}
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {trafficReport.frameNumber}
              </td>
              <td className="min-w-48 px-3 align-middle whitespace-nowrap text-sm text-gray-500">
                {parseDayMonthYearHourMinute(new Date(trafficReport.createdAt))}
              </td>
              <td
                className={`${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                } sticky right-0 min-w-16 w-16 align-middle whitespace-nowrap text-center text-sm font-medium`}
              >
                {trafficReport.s3Key && (
                  <button
                    type="button"
                    className="text-blue-600 hover:text-blue-900"
                    onClick={() => {
                      handleClick(trafficReport.id);
                    }}
                  >
                    Ver
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
