import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { userInfoContext } from '../../context/UserInfoContext/UserInfoContext';
import { createSetupIntent } from '../../services/stripe';
import SetupForm from './SetupForm';

function NewPaymentMethodModal({
  isOpen,
  setIsOpen,
  save,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  save: () => void;
}) {
  const modal = useRef(null);

  useClickOutside(modal, () => setIsOpen(false));

  const [clientSecret, setClientSecret] = useState(null);

  const { accountInfo } = useContext(userInfoContext);

  const { getAccessTokenSilently } = useAuth0();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const close = () => {
    setIsOpen(false);
    save();
  };

  useEffect(() => {
    (async () => {
      if (isOpen) {
        const token = await getAccessTokenSilently();
        // eslint-disable-next-line camelcase
        const { client_secret } = await createSetupIntent(
          accountInfo.organizationId,
          token,
        );
        setClientSecret(client_secret);
      }
    })();
  }, [isOpen, accountInfo?.organizationId]);

  if (!isOpen) return null;

  return (
    <div className="fixed left-0 top-0 z-50 w-screen h-screen bg-black bg-opacity-40 flex justify-center items-center">
      <div
        ref={modal}
        className="bg-white rounded-md px-10 py-5 shadow-md gap-4 flex flex-col"
        style={{ width: '800px', maxHeight: '90vh', overflowY: 'auto' }}
      >
        <h3 className="text-xl font-medium">Nuevo método de pago</h3>

        {clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <SetupForm close={close} />
          </Elements>
        ) : (
          'Conectando con Stripe...'
        )}
      </div>
    </div>
  );
}

export default NewPaymentMethodModal;
