import { fuelDictionary } from '../enums/fuelDictionary';
import { ccaaDictionary } from '../enums/ccaaDictionary';
import { serviceTypeDict } from '../enums/serviceTypeDict';
import { Car, CarWithPrice, Company, User, FILE_TYPE } from '../types/types';
import parseDate from './parseDate';

export type GeneratedFile = {
  owner: User | Company | Car;
  img: string | null;
  type: FILE_TYPE;
};

export type Section = {
  id: string;
  title: string;
  data: {
    label: string;
    displayValue: string;
    value: string;
  }[];
};

export const generateCompanySection = (company: Company) => ({
  id: company.id,
  title: 'Datos de la empresa',
  data: [
    {
      label: 'legalName',
      displayValue: 'Razón social',
      value: company.legalName,
    },
    {
      label: 'nif',
      displayValue: 'CIF',
      value: company.nif,
    },
    {
      label: 'fiscalAddressAddress',
      displayValue: 'Dirección',
      value: company.fiscalAddressAddress,
    },
    {
      label: 'fiscalAddressCity',
      displayValue: 'Ciudad',
      value: company.fiscalAddressCity,
    },
    {
      label: 'fiscalAddressProvince',
      displayValue: 'Provincia',
      value: company.fiscalAddressProvince,
    },
    {
      label: 'fiscalAddressState',
      displayValue: 'Comunidad autónoma',
      value: ccaaDictionary[company.fiscalAddressState],
    },
    {
      label: 'fiscalAddressZipCode',
      displayValue: 'Código postal',
      value: company.fiscalAddressZipCode,
    },
  ],
});

export const generateUserSection = (user: User, title: string) => ({
  id: user.id,
  title,
  data: [
    {
      label: 'name',
      displayValue: 'Nombre',
      value: user.name?.toUpperCase(),
    },
    {
      label: 'surname',
      displayValue: 'Apellidos',
      value: user.surname?.toUpperCase(),
    },
    {
      label: 'dni',
      displayValue: 'DNI',
      value: user.dni?.toUpperCase(),
    },
    {
      label: 'address',
      displayValue: 'Dirección',
      value: user.address?.toUpperCase(),
    },
    {
      label: 'city',
      displayValue: 'Ciudad',
      value: user.city?.toUpperCase(),
    },
    {
      label: 'province',
      displayValue: 'Provincia',
      value: user.province?.toUpperCase(),
    },
    {
      label: 'ccaa',
      displayValue: 'Comunidad Autónoma',
      value: (user.ccaa ? ccaaDictionary[user.ccaa] : '')?.toUpperCase(),
    },
    {
      label: 'zipCode',
      displayValue: 'Código postal',
      value: user.zipCode,
    },
    {
      label: 'phone',
      displayValue: 'Teléfono',
      value: user.phone,
    },
    {
      label: 'email',
      displayValue: 'Email',
      value: user.email,
    },
  ],
});

export const generateVehicleSection = (vehicle: CarWithPrice): Section[] => [
  {
    id: vehicle?.id,
    title: 'Datos del vehículo',
    data: [
      {
        label: 'brand',
        displayValue: 'Marca',
        value: vehicle?.brand,
      },
      {
        label: 'model',
        displayValue: 'Modelo y versión',
        value: vehicle?.model,
      },
      {
        label: 'plate',
        displayValue: 'Matrícula',
        value: vehicle?.plate,
      },
      {
        label: 'frameNumber',
        displayValue: 'Número de bastidor',
        value: vehicle?.frameNumber,
      },
      {
        label: 'enrollmentDate',
        displayValue: 'Fecha de 1ᵃ matriculación',
        value: vehicle?.enrollmentDate
          ? parseDate(vehicle?.enrollmentDate)
          : undefined,
      },
      {
        label: 'fuel',
        displayValue: 'Combustible',
        value: fuelDictionary[vehicle?.fuel],
      },
      {
        label: 'kms',
        displayValue: 'Kilómetros',
        value: String(vehicle?.kms ?? '---'),
      },
      {
        label: 'price',
        displayValue: 'Precio',
        value: vehicle?.price ? `${vehicle?.price} €` : '---',
      },
      {
        label: 'serviceType',
        displayValue: 'Servicio',
        value: vehicle?.serviceType
          ? serviceTypeDict[vehicle?.serviceType]
          : '---',
      },
      {
        label: 'cc',
        displayValue: 'Cubicaje (cc)',
        value: vehicle?.cc,
      },
      {
        label: 'powerKw',
        displayValue: 'Potencia (kw)',
        value: vehicle?.powerKw,
      },
      {
        label: 'CV',
        displayValue: 'Potencia (cv)',
        value: vehicle?.powerKw
          ? String((Number(vehicle?.powerKw) * 1.3596).toFixed(2))
          : '',
      },
    ],
  },
];

export const generateUserFileSection = (user: User): GeneratedFile[] => [
  {
    owner: user,
    img: user.photoDni?.[0],
    type: FILE_TYPE.DOI_FRONT,
  },
  {
    owner: user,
    img: user.photoDni?.[1],
    type: FILE_TYPE.DOI_BACK,
  },
];

export const generateCompanyFileSection = (
  company: Company,
): GeneratedFile[] => [
  {
    owner: company,
    img: company.nifFile,
    type: FILE_TYPE.TIF,
  },
  {
    owner: company.representative,
    img: company.representative.photoDni?.[0],
    type: FILE_TYPE.DOI_FRONT,
  },
  {
    owner: company.representative,
    img: company.representative.photoDni?.[1],
    type: FILE_TYPE.DOI_BACK,
  },
];

export const generateVehicleFiles = (car: Car): GeneratedFile[] => [
  {
    owner: car,
    img: car.circulationPermit,
    type: FILE_TYPE.CIRCULATION_PERMIT,
  },
  { owner: car, img: car.technicalSheet, type: FILE_TYPE.TECHNICAL_SHEET },
];
