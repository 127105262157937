import React, { useContext, useEffect, useState } from 'react';
import { MdArrowBack, MdOutlineWarningAmber, MdSend } from 'react-icons/md';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import RecipientsTabGroup, {
  RecipientRolesTabGroupOptions,
} from './components/RecipientsTabGroup';
import RecipientItem, {
  AvailableRecipientRoles,
  hasCommunicationMethod,
} from './components/RecipientItem';
import {
  BateTramit,
  RegistrationTramit,
  Transfer,
  User,
} from '../../../../types/types';
import { isRegistrationTramit } from '../../../../types/RegistrationTramit';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';
import { DigitallySignSteps } from '../DigitallySignStepsManager';
import { ChooseDocumentSourceStepOptions } from '../ChooseDocumentSourceStep';
import { isBateTramit } from '../../../../types/BateTramit';
import { isTransfer } from '../../../../types/Transfer';
import { getRecipient } from '../../types/Recipient';

export default function SendDocumentStep({
  procedure,
  hide,
}: {
  readonly procedure: Transfer | BateTramit | RegistrationTramit;
  readonly hide: () => void;
}) {
  const [selectedRecipientRole, setSelectedRecipientRole] =
    useState<RecipientRolesTabGroupOptions>(
      RecipientRolesTabGroupOptions.BUYER,
    );
  const [isSendingDocument, setIsSendingDocument] = useState(false);

  const { setCurrentDigitallySignStep, chosenDocumentSource, documentPDF } =
    useContext(digitallySignContext);

  const { getAccessTokenSilently } = useAuth0();

  const [localBuyer, setLocalBuyer] = useState<User>(null);
  const [localSeller, setLocalSeller] = useState<User>(null);

  useEffect(() => {
    setLocalBuyer(getRecipient(procedure.buyer));
    if (isTransfer(procedure) || isBateTramit(procedure)) {
      setLocalSeller(getRecipient(procedure.seller));
    }
  }, []);

  const handleSendDocument = async () => {
    setIsSendingDocument(true);

    const signers = [];

    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER) {
      signers.push({
        id: localBuyer.id,
        name: `${localBuyer.name} ${localBuyer.surname}`,
        role: 'buyer',
        phone: localBuyer.phone,
        email: localBuyer.email,
      });
    }

    if (selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER) {
      signers.push({
        id: localSeller.id,
        name: `${localSeller.name} ${localSeller.surname}`,
        role: 'seller',
        phone: localSeller.phone,
        email: localSeller.email,
      });
    }

    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) {
      signers.push(
        {
          id: localBuyer.id,
          name: `${localBuyer.name} ${localBuyer.surname}`,
          role: 'buyer',
          phone: localBuyer.phone,
          email: localBuyer.email,
        },
        {
          id: localSeller.id,
          name: `${localSeller.name} ${localSeller.surname}`,
          role: 'seller',
          phone: localSeller.phone,
          email: localSeller.email,
        },
      );
    }

    let entityType = '';
    let entityCode = '';

    if (isBateTramit(procedure)) {
      entityType = 'bate';
      entityCode = procedure.bate.bateCode;
    }

    if (isRegistrationTramit(procedure)) {
      entityType = 'registration';
      entityCode = procedure.registration.registrationCode;
    }

    if (isTransfer(procedure)) {
      entityType = 'transaction';
      entityCode = procedure.transaction.transactionCode;
    }

    const token = await getAccessTokenSilently();
    await axios.post(
      `${process.env.REACT_APP_SUZUKI_URL}/documents-pro`,
      [
        {
          entityType,
          entityCode,
          pdfInBase64: documentPDF.data,
          id: uuidv4(),
          title: documentPDF.fileName,
          type: 'custom',
          signers,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    setIsSendingDocument(false);
    hide();
  };

  const handleGoBack = () => {
    if (chosenDocumentSource === ChooseDocumentSourceStepOptions.USE_TEMPLATE) {
      setCurrentDigitallySignStep(DigitallySignSteps.EDIT_GENERATED_DOCUMENT);
      return;
    }
    setCurrentDigitallySignStep(DigitallySignSteps.UPLOAD_DOCUMENT);
  };

  const isAbleToSendDocument = () => {
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER) {
      return hasCommunicationMethod(localBuyer);
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER) {
      return hasCommunicationMethod(localSeller);
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) {
      return (
        hasCommunicationMethod(localBuyer) ||
        hasCommunicationMethod(localSeller)
      );
    }
    return false;
  };

  const canShowWarningMessage = (): boolean => {
    if (selectedRecipientRole !== RecipientRolesTabGroupOptions.BOTH)
      return false;

    return (
      !hasCommunicationMethod(localBuyer) ||
      !hasCommunicationMethod(localSeller)
    );
  };

  return (
    <section className="flex flex-col items-center gap-9 w-[700px]">
      <header className="flex flex-col gap-3 w-full">
        <h3 className="text-4xl font-bold text-zinc-700">
          Firmar nuevo documento
        </h3>
        <span className="text-2xl text-zinc-600">Enviar documento</span>
        <span className="font-light text-zinc-600">
          Selecciona el medio de envío (si correo electrónico o teléfono móvil)
          para que el destinatario reciba la solicitud de firma digital del
          documento.
        </span>
      </header>
      <article className="flex items-center gap-5 w-full p-4 border-2 border-blue-800 rounded-xl">
        <figure className="size-16 bg-zinc-100 rounded-xl" />
        <section className="flex flex-col">
          <span className="text-sm text-blue-800">Documento generado</span>
          <span className="font-bold text-zinc-700">
            {documentPDF?.fileName}
          </span>
        </section>
      </article>
      {!isRegistrationTramit(procedure) && (
        <RecipientsTabGroup
          selectedRecipient={selectedRecipientRole}
          setSelectedRecipient={setSelectedRecipientRole}
        />
      )}
      <section className="flex flex-col gap-6 w-full">
        {(selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER ||
          selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) && (
          <RecipientItem
            recipientRole={AvailableRecipientRoles.BUYER}
            recipient={localBuyer}
          />
        )}
        {(selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER ||
          selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) && (
          <RecipientItem
            recipientRole={AvailableRecipientRoles.SELLER}
            recipient={localSeller}
          />
        )}
      </section>
      {canShowWarningMessage() && (
        <article className="flex items-center gap-2 bg-yellow-50 text-yellow-800 border border-yellow-800 rounded-xl p-4 w-full">
          <MdOutlineWarningAmber className="size-5" />
          La solicitud se enviará únicamente al{' '}
          {hasCommunicationMethod(localBuyer) ? 'comprador' : 'vendedor'}.
        </article>
      )}
      <footer className="w-full flex justify-between gap-8">
        <button
          type="button"
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
          onClick={handleGoBack}
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          disabled={!isAbleToSendDocument() || isSendingDocument}
          className="flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-900 active:bg-blue-950"
          onClick={handleSendDocument}
        >
          Enviar a firmar
          <MdSend className="size-5" />
        </button>
      </footer>
    </section>
  );
}
