import React, { Dispatch, SetStateAction, useContext } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import {
  AsyncState,
  BateTramit,
  RegistrationTramit,
  Transfer,
} from '../../../../../types/types';
import { Template as TemplateType } from '../ChooseTemplateStep';
import Spinner from '../../../../../components/Spinner';
import Template from './Template';
import { digitallySignContext } from '../../../../../context/DigitallySignContext/DigitallySignContext';
import { toasterContext } from '../../../../../context/ToasterContext/ToasterContext';
import { DigitallySignSteps } from '../../DigitallySignStepsManager';
import classNames from '../../../../../helpers/classNames';

type Props = Readonly<{
  templates: AsyncState<TemplateType[]>;
  selectedTemplateId: string;
  setSelectedTemplateId: Dispatch<SetStateAction<string>>;
  setSelectedDocId: Dispatch<SetStateAction<string | null>>;
  procedure: Transfer | BateTramit | RegistrationTramit;
  injectTemplate: (
    tramit: Transfer | BateTramit | RegistrationTramit,
  ) => Promise<string>;
  injectedDocumentId: AsyncState<string>;
}>;

export default function TemplatePicker({
  templates,
  selectedTemplateId,
  setSelectedTemplateId,
  setSelectedDocId,
  procedure,
  injectTemplate,
  injectedDocumentId,
}: Props) {
  const { setCurrentDigitallySignStep } = useContext(digitallySignContext);
  const { setToasterData } = useContext(toasterContext);

  const handleNextStep = async () => {
    const newDocumentId = await injectTemplate(procedure);

    if (!newDocumentId) {
      setToasterData({
        title: 'Error generando documento',
        message: 'Ha ocurrido un error generando el documento',
        type: 'ERROR',
      });
      return;
    }

    setSelectedDocId(newDocumentId);

    setCurrentDigitallySignStep(DigitallySignSteps.EDIT_GENERATED_DOCUMENT);
  };

  const handlePreviousStep = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);
  };

  return (
    <section
      className={classNames(
        'flex flex-col h-[720px] justify-between gap-10',
        selectedTemplateId ? 'w-1/2' : 'w-[700px]',
      )}
    >
      <header className="flex flex-col gap-3">
        <h3 className="text-4xl font-bold text-zinc-700">
          Firmar nuevo documento
        </h3>
        <span className="text-2xl text-zinc-600">Elegir plantilla</span>
      </header>
      {templates.isLoading && (
        <article
          className="flex items-center justify-center h-full"
          role="status"
        >
          <Spinner color="text-blue-700" size={16} marginTop={0} />
        </article>
      )}
      {templates.error && (
        <span className="text-red-500">{templates.error}</span>
      )}
      {!templates.data.length && !templates.isLoading && !templates.error ? (
        <article className="flex items-center justify-center">
          <span>No se encontraron plantillas</span>
        </article>
      ) : (
        <section className="flex flex-col gap-4 h-full p-1 overflow-y-auto">
          {Boolean(templates.data.length) &&
            !templates.isLoading &&
            templates.data.map((template) => (
              <Template
                key={template?.id}
                template={template}
                previewTemplateId={selectedTemplateId}
                setPreviewTemplateId={setSelectedTemplateId}
              />
            ))}
        </section>
      )}
      <footer className="w-full flex justify-between gap-8">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          disabled={!selectedTemplateId || injectedDocumentId.isLoading}
          onClick={handleNextStep}
          className={classNames(
            'flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed',
            selectedTemplateId &&
              !injectedDocumentId.isLoading &&
              'hover:bg-blue-900 active:bg-blue-950',
          )}
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </section>
  );
}
