import React from 'react';
import BaseModal from '../../components/BaseModal';
import { DigitallySignContextProvider } from '../../context/DigitallySignContext/DigitallySignContext';
import DigitallySignStepsManager from './steps/DigitallySignStepsManager';
import {
  Transfer,
  BateTramit,
  RegistrationTramit,
  TemplateType,
} from '../../types/types';

type DigitallySignModalProps = {
  isShown: boolean;
  hide: () => void;
  readonly templateType: TemplateType;
  readonly procedure: Transfer | BateTramit | RegistrationTramit;
};

export default function DigitallySignModal({
  procedure,
  templateType,
  isShown,
  hide,
}: Readonly<DigitallySignModalProps>) {
  return (
    <BaseModal
      isOpen={isShown}
      onClose={hide}
      width="w-contain"
      padding={false}
    >
      <section className="h-full w-full p-9">
        <DigitallySignContextProvider>
          <DigitallySignStepsManager
            hide={hide}
            procedure={procedure}
            templateType={templateType}
          />
        </DigitallySignContextProvider>
      </section>
    </BaseModal>
  );
}
