import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { DigitallySignSteps } from '../../modals/DigitallySignModal/steps/DigitallySignStepsManager';
import { ChooseDocumentSourceStepOptions } from '../../modals/DigitallySignModal/steps/ChooseDocumentSourceStep';
import { DocumentPDF } from '../../modals/DigitallySignModal/types/types';

export type DigitallySignContextType = {
  currentStep: number;
  setCurrentDigitallySignStep: Dispatch<SetStateAction<DigitallySignSteps>>;
  generatedDocumentId: string | null;
  setGeneratedDocumentId: Dispatch<SetStateAction<string | null>>;
  chosenDocumentSource: ChooseDocumentSourceStepOptions | null;
  setChosenDocumentSource: Dispatch<
    SetStateAction<ChooseDocumentSourceStepOptions | null>
  >;
  documentPDF: DocumentPDF | null;
  setDocumentPDF: Dispatch<SetStateAction<DocumentPDF | null>>;
};

export const digitallySignContext = createContext<DigitallySignContextType>(
  {} as DigitallySignContextType,
);

export function DigitallySignContextProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [generatedDocumentId, setGeneratedDocumentId] = useState<string | null>(
    null,
  );
  const [currentDigitallySignStep, setCurrentDigitallySignStep] =
    useState<DigitallySignSteps>(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);
  const [chosenDocumentSource, setChosenDocumentSource] =
    useState<ChooseDocumentSourceStepOptions>(null);
  const [documentPDF, setDocumentPDF] = useState<DocumentPDF>(null);

  const value = useMemo(
    () => ({
      currentStep: currentDigitallySignStep,
      setCurrentDigitallySignStep,
      generatedDocumentId,
      setGeneratedDocumentId,
      chosenDocumentSource,
      setChosenDocumentSource,
      documentPDF,
      setDocumentPDF,
    }),
    [currentDigitallySignStep, generatedDocumentId, documentPDF],
  );

  return (
    <digitallySignContext.Provider value={value}>
      {children}
    </digitallySignContext.Provider>
  );
}
