import PaginationFooter from '../../../../components/PaginationFooter';
import { parseDateHourYear } from '../../../../helpers';
import { handleViewTrafficReport } from '../../../../helpers/handleViewTrafficReport';
import { useServices } from '../../../../services';

export default function TrafficReportsTable({
  reports,
  total,
  page,
  goNext,
  goPrev,
  setErrorMessage,
  isPro,
}) {
  const { getTrafficReportById } = useServices();

  return (
    <div className="flex flex-col mt-2">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle min-w-full sm:px-6 lg:px-8 flex flex-col">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Matrícula / VIN
                  </th>
                  {isPro && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cuenta
                    </th>
                  )}
                  {!isPro && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, i) => (
                  <tr
                    key={report.id}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {report.plate ?? report.frameNumber}
                    </td>
                    {isPro && (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {report.accountName}
                      </td>
                    )}
                    {!isPro && (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {report.email}
                      </td>
                    )}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {parseDateHourYear(report.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:text-blue-600">
                      {report.s3Key && (
                        <button
                          type="button"
                          onClick={() =>
                            handleViewTrafficReport({
                              trafficReportId: report.id,
                              getTrafficReportById,
                              setErrorMessage,
                            })
                          }
                        >
                          Ver
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationFooter
              data={reports}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              total={total}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
