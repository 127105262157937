import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import { PencilIcon } from '@heroicons/react/solid';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PaperClipIcon, InformationCircleIcon } from '@heroicons/react/outline';

import Card from '../ModuleWrapper';
import ItpLiquidationLink from './ItpLiquidationLink';
import FileLink from '../../../../../components/FileLink';
import { ccaaDictionary } from '../../../../../helpers/enums';
import ItpLiquidatedEditorModal from './TaxDetailsModals/ItpLiquidatedEditorModal';
import TotalItpPaidEditorModal from './TaxDetailsModals/TotalItpPaidEditorModal';
import TotalItpToPayEditorModal from './TaxDetailsModals/TotalItpToPayEditorModal';
import PriceContractValueEditorModal from './TaxDetailsModals/PriceContractValueEditorModal';
import { transferDetailsContext } from '../../../../../context/transfer-details.context';

export default function TaxDetails({ setModalType, setCurrentVehicleValue }) {
  const [extraVehicleData, setExtraVehicleData] = useState({
    tableValue: null,
    depreciationPercentage: null,
    fiscalValue: null,
    appliedPercentage: null,
    calculatedItp: null,
    ccaa: null,
  });

  const [
    isPriceContractValueEditorModalOpen,
    setIsPriceContractValueEditorModalOpen,
  ] = useState(false);
  const [isItpPaidEditorModalOpen, setItpPaidEditorModalOpen] = useState(false);
  const [isTotalItpToPayEditorModalOpen, setIsTotalItpToPayEditorModalOpen] =
    useState(false);
  const [isItpLiquidatedEditorModalOpen, setItpLiquidatedEditorModalOpen] =
    useState(false);

  const { transfer, forceUpdate } = useContext(transferDetailsContext);
  const { transaction, buyer, car } = transfer;

  const ccaa = useMemo(() => buyer?.ccaa || buyer?.fiscalAddressState, [buyer]);

  useEffect(() => {
    (async () => {
      const isCarWithoutModel = !car?.isMoto && !car?.model;
      const isMotoWithoutCc = car?.isMoto && !car?.cc;

      if (
        !transaction ||
        !buyer ||
        !buyer?.ccaa ||
        buyer?.representative ||
        !car?.enrollmentDate ||
        isCarWithoutModel ||
        isMotoWithoutCc
      )
        return;

      const carUrl = `${
        process.env.REACT_APP_BASE_API_URL
      }/itp/calculate?ccaa=${encodeURIComponent(
        buyer?.ccaa,
      )}&model=${encodeURIComponent(
        car?.model,
      )}&enrollmentDate=${encodeURIComponent(car?.enrollmentDate)}`;

      const yearsDifference = +new Date() - +new Date(car.enrollmentDate);
      const yearsOld = Math.floor(
        yearsDifference / (365.24 * 24 * 60 * 60 * 1000),
      );

      const motoUrl = `${
        process.env.REACT_APP_BASE_API_URL
      }/itp/calculate/moto?ccaa=${encodeURIComponent(
        buyer?.ccaa,
      )}&years=${yearsOld}&cc=${car?.cc}`;

      const { data } = await axios.get(car.isMoto ? motoUrl : carUrl);

      setCurrentVehicleValue(data.deprecated_value);
      setExtraVehicleData({
        tableValue: data.value,
        appliedPercentage: data.itp_coeficient,
        ccaa: buyer.ccaa,
        depreciationPercentage: data.deprecation_coeficient,
        fiscalValue: data.deprecated_value,
        calculatedItp: data.itp,
      });
    })();
  }, []);

  const closeModal = () => {
    setItpLiquidatedEditorModalOpen(false);
    setItpPaidEditorModalOpen(false);
    setIsTotalItpToPayEditorModalOpen(false);
    setIsPriceContractValueEditorModalOpen(false);
    forceUpdate();
  };

  return (
    <>
      <ItpLiquidatedEditorModal
        closeModal={closeModal}
        isOpen={isItpLiquidatedEditorModalOpen}
        itpLiquidated={transaction.itpLiquidated}
        transactionCode={transaction.transactionCode}
      />
      <TotalItpPaidEditorModal
        closeModal={closeModal}
        totalItpPaid={transaction.totalItpPaid}
        isOpen={isItpPaidEditorModalOpen}
        transactionCode={transaction.transactionCode}
      />
      <TotalItpToPayEditorModal
        closeModal={closeModal}
        totalItpToPay={transaction.totalItpToPay}
        isOpen={isTotalItpToPayEditorModalOpen}
        transactionCode={transaction.transactionCode}
      />
      <PriceContractValueEditorModal
        closeModal={closeModal}
        priceContractValue={transaction.priceContractValue}
        isOpen={isPriceContractValueEditorModalOpen}
        transactionCode={transaction.transactionCode}
      />
      <Card
        title="Impuestos"
        button={
          <ItpLiquidationLink
            ccaa={ccaa}
            transaction={transaction}
            forceUpdate={forceUpdate}
            plate={car.plate}
          />
        }
      >
        <Tooltip
          id="itpPrice-tooltip"
          style={{
            marginTop: '0',
            fontSize: '15px',
          }}
          content="ITP pagado desde el widget"
          place="top"
        />
        <Tooltip
          id="totalItpToPay-tooltip"
          style={{
            marginTop: '0',
            fontSize: '15px',
          }}
          content="ITP total que deberá pagar el cliente para poder liquidar"
          place="top"
        />
        <Tooltip
          id="totalItpPaid-tooltip"
          style={{
            marginTop: '0',
            fontSize: '15px',
          }}
          content="ITP total pagado por el cliente"
          place="top"
        />
        <Tooltip
          id="itpLiquidated-tooltip"
          style={{
            marginTop: '0',
            fontSize: '15px',
          }}
          content="ITP liquidado con la adm. pública"
          place="top"
        />
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Valoración en tablas de Hacienda
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {extraVehicleData.tableValue !== null ? (
                  <>{extraVehicleData.tableValue} € </>
                ) : (
                  <span className="text-gray-400">No disponible</span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Porcentaje aplicado
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {extraVehicleData.ccaa &&
                extraVehicleData.appliedPercentage !== null ? (
                  <>
                    {ccaaDictionary[extraVehicleData.ccaa]} -{' '}
                    {extraVehicleData.appliedPercentage}%
                  </>
                ) : (
                  <span className="text-gray-400">No disponible</span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Valor fiscal
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {extraVehicleData.fiscalValue !== null ? (
                  <>{extraVehicleData.fiscalValue} € </>
                ) : (
                  <span className="text-gray-400">No disponible</span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Precio de contrato/factura
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.priceContractValue !== null ? (
                  <>{transaction.priceContractValue} €</>
                ) : (
                  <span className="text-gray-400">No disponible</span>
                )}
                <PencilIcon
                  className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
                  onClick={() => {
                    setIsPriceContractValueEditorModalOpen(true);
                  }}
                />
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                ITP (Calculado){' '}
                <InformationCircleIcon
                  aria-hidden="true"
                  className="inline shrink-0 h-5 w-5 text-gray-400"
                  data-tooltip-id="itpPrice-tooltip"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.itpPrice} €
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                ITP (Definitivo a pagar){' '}
                <InformationCircleIcon
                  aria-hidden="true"
                  className="inline shrink-0 h-5 w-5 text-gray-400"
                  data-tooltip-id="totalItpToPay-tooltip"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.totalItpToPay} €{' '}
                <PencilIcon
                  className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
                  onClick={() => {
                    setIsTotalItpToPayEditorModalOpen(true);
                  }}
                />
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                ITP (Importe Pagado){' '}
                <InformationCircleIcon
                  aria-hidden="true"
                  className="inline shrink-0 h-5 w-5 text-gray-400"
                  data-tooltip-id="totalItpPaid-tooltip"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.totalItpPaid} €{' '}
                <PencilIcon
                  className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
                  onClick={() => {
                    setItpPaidEditorModalOpen(true);
                  }}
                />
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                ITP (Liquidado){' '}
                <InformationCircleIcon
                  aria-hidden="true"
                  className="inline shrink-0 h-5 w-5 text-gray-400"
                  data-tooltip-id="itpLiquidated-tooltip"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.itpLiquidated} €{' '}
                <PencilIcon
                  className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
                  onClick={() => {
                    setItpLiquidatedEditorModalOpen(true);
                  }}
                />
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">CET</dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                {transaction.cetCode}
                <PencilIcon
                  className="h-4 text-indigo-500 hover:text-indigo-600 cursor-pointer ml-2"
                  onClick={() => {
                    setModalType('cetCode');
                  }}
                />
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Documentos</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        Modelo 620
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {transaction.paidItpFile ? (
                        <>
                          <FileLink source={transaction.paidItpFile} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setModalType('paidItpFile')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setModalType('paidItpFile')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        Justificante de Pago ITP
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {transaction.paidItpProof ? (
                        <>
                          <FileLink source={transaction.paidItpProof} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setModalType('paidItpProof')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setModalType('paidItpProof')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </Card>
    </>
  );
}
