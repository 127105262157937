import React, { useContext } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from '../../../helpers/classNames';
import { DigitallySignSteps } from './DigitallySignStepsManager';
import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';

export default function EditGeneratedDocumentStep() {
  const { generatedDocumentId, setCurrentDigitallySignStep, setDocumentPDF } =
    useContext(digitallySignContext);
  const { getAccessTokenSilently } = useAuth0();

  const fetchDocumentPDF = async () => {
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${process.env.REACT_APP_SUZUKI_URL}/templates/export-pdf/${generatedDocumentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  const handlePreviousStep = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_TEMPLATE);
  };

  const handleNextStep = async () => {
    setCurrentDigitallySignStep(DigitallySignSteps.SEND_DOCUMENT);
    setDocumentPDF(await fetchDocumentPDF());
  };

  return (
    <section className="relative">
      <header className="flex flex-col gap-3">
        <h3 className="text-4xl font-bold text-zinc-700">
          Firmar nuevo documento
        </h3>
        <span className="text-2xl text-zinc-600">Editar documento</span>
      </header>
      <iframe
        title="Editar documento"
        width="1000"
        className={classNames('my-10 h-[500px] 2xl:h-[675px]')}
        src={`https://docs.google.com/document/d/${generatedDocumentId}`}
      />
      <footer className="w-full flex justify-between gap-8">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          onClick={handleNextStep}
          className="flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-900 active:bg-blue-950"
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </section>
  );
}
