import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XCircleIcon, XIcon } from '@heroicons/react/solid';

import Spinner from '../../../../../../components/Spinner';
import BaseModal from '../../../../../../components/BaseModal';
import { useServices } from '../../../../../../services';

export default function PriceContractValueEditorModal({
  isOpen,
  closeModal,
  priceContractValue,
  transactionCode,
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newPriceContractValue, setNewPriceContractValue] =
    useState(priceContractValue);

  const { editTransaction } = useServices();

  const resetModal = () => {
    setIsLoading(false);
    setError('');
    closeModal();
  };

  const handleSubmit = async () => {
    if (newPriceContractValue < 0) {
      setError('El Precio no puede ser negativo.');
      return;
    }
    try {
      setIsLoading(true);
      await editTransaction(transactionCode, {
        priceContractValue: newPriceContractValue,
      });
      setIsLoading(false);
      closeModal();
    } catch {
      setError('Error al editar precio');
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <BaseModal isOpen={isOpen} onClose={resetModal} overflowVisible={false}>
      {error ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">{error}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={resetModal}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-md leading-6 font-medium text-gray-900"
                >
                  Editar precio de contrato/factura
                </Dialog.Title>
                <div className="mt-2">
                  <label htmlFor="priceContractValue" className="sr-only">
                    Precio de contrato/factura
                  </label>
                  <input
                    type="number"
                    name="priceContractValue"
                    id="priceContractValue"
                    value={newPriceContractValue}
                    onChange={(e) => setNewPriceContractValue(e.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            {error && <p className="text-red-500">{error}</p>}
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading && <Spinner color="text-white" size="5" />}
              {isLoading ? 'Guardando...' : 'Guardar'}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={resetModal}
            >
              Cancelar
            </button>
          </div>
        </>
      )}
    </BaseModal>
  );
}
